<template>
    <div class="missao">
        <Loading :active="loading" is-full-screen />
        <SelectCity ref="city" @setCity="setCity" :all="true" />
        <HomeRemote :remote="remote" />
        <Nota :texto="$t('missao.nota')" />
        <div class="cards" v-if="textSelect.title">
            <div class="flex justify-center items-center" v-if="!textSelect.varios"><img class="igg"
                    :src="getSrc(textSelect)" @error="getImage" /></div>
            <div>
                <div>
                    <div style="margin-top: 10px">
                        <strong>{{ type === 'pokemon' && !textSelect.varios ? `#${textSelect.value} - ` : '' }}
                            {{
                            $i18n.locale === 'pt-br'
                            ? textSelect.title.texto_pt || textSelect.title.texto
                            : $i18n.locale === 'es-es'
                            ? textSelect.title.texto_es || textSelect.title.texto
                            : textSelect.title.texto_en || textSelect.title.texto
                            }}</strong>
                    </div>
                </div>
                <div v-if="textSelect.missao.length && !textSelect.varios">
                    {{
                    $i18n.locale === 'pt-br'
                    ? textSelect.missao[0].texto_pt
                    : $i18n.locale === 'es-es'
                    ? textSelect.missao[0].texto_es
                    : textSelect.missao[0].texto_en
                    }}
                </div>
                <div v-if="!textSelect.varios" class="flex justify-center items-center">
                    <img class="flag" :src="flag[textSelect.location]" style="margin-right: 5px" />
                    {{
                    $i18n.locale === 'pt-br'
                    ? getCity(textSelect.location).title_pt
                    : $i18n.locale === 'es-es'
                    ? getCity(textSelect.location).title_es
                    : getCity(textSelect.location).title
                    }}
                </div>

                <div v-if="!textSelect.varios">
                    <div>{{ getCity(textSelect.location).date }}</div>
                    <Termina :inicio="moment().tz(getCity(textSelect.location).timezone)" :fim="
                      moment()
                        .tz(getCity(textSelect.location).timezone)
                        .set({
                          date: textSelect.datefim,
                          hour: 23,
                          minute: 59,
                          second: 59,
                          millisecond: 59
                        })
                    " @end="getEnd" v-if="!end" />
                    <div v-else style="color: red;">
                        {{ $t('eventos.finalizado') }}
                    </div>
                </div>

                <!-- {{ textSelect }} -->
                <div>
                    {{ $t('missao.total') }}: {{ result.length }} | Sets:
                    {{ Math.round(result.length / 3) }}
                </div>
            </div>
        </div>
        <br v-if="textSelect.title" />
        <div style="display: flex;justify-content: space-between;margin-top: 10px">
            <div class="flipper btn-container" v-if="result.length">
                <a class="btn btn-yellow" href="#" @click.prevent="getEmpty('initial')">{{ $t('missao.limpar') }}</a>
            </div>
            <div class="flipper btn-container" v-if="result.length">
                <a class="btn btn-down" href="#" @click.prevent="createAndOpenFile">{{
                    $t('missao.downgpx')
                    }}</a>
            </div>
            <vue-flip v-model="activeMapa" active-click height="50px" width="100%" class="btn-container"
                v-if="result.length > 1">
                <template v-slot:front>
                    <div class="btn" @click="verAndOpenFile">{{ $t('missao.mapa') }}</div>
                </template>
                <template v-slot:back>
                    <div class="btn" @click="gpx = ''">{{ $t('missao.mapa') }}</div>
                </template>
            </vue-flip>
            <vue-flip active-click height="50px" width="100%" class="btn-container" v-if="result.length > 1 && botao">
                <template v-slot:front>
                    <div class="btn" @click="setSaidaCopy">
                        {{ $t('missao.gerarLista') }}
                    </div>
                </template>
                <template v-slot:back>
                    <div class="btn">{{ $t('missao.gerarLista') }}</div>
                </template>
            </vue-flip>
        </div>
        <Mapa v-if="gpx" :gpx="gpx" style="margin-bottom: 15px" />
        <!--  <button @click.prevent="getList">Converter</button>
    <button @click.prevent="getEmpty">Limpar</button> -->
        <div class="flex">
            <div class="btn btn-velocity" href="#" @click.prevent="selectVelocity" v-if="resultPage.length">
                {{
                velocity
                ? $t('missao.velocityAtivado')
                : $t('missao.velocityDesativado')
                }}
            </div>
            <div class="btn-velocity flex justify-center items-center" @click.prevent="setWindow"
                v-if="platform === 'android' && result.length">
                {{ $t('missao.flutuar') }}
            </div>
        </div>
        <ul class="home">
            <li v-for="(r, k) in resultPage" :key="k">
                <div v-if="k % 3 === 0">
                    <strong>Set {{ k / 3 + page * 7 + 1 }}</strong>
                    <vue-flip active-click height="50px" width="100%" class="btn-copy" v-model="r.copy3">
                        <template v-slot:front>
                            <div class="btn" @click="
                              copy3set(
                                `${resultPage[k].coord}\n${
                                  resultPage[k + 1] ? resultPage[k + 1].coord : ''
                                }\n${
                                  resultPage[k + 2] ? resultPage[k + 2].coord : ''
                                }\n【Ｃｏｏｒｄｓ ＧＯ】`
                              )
                            ">
                                {{ $t('missao.copiar') }}
                            </div>
                        </template>
                        <template v-slot:back>
                            <div class="btn">{{ $t('missao.copiar') }}</div>
                        </template>
                    </vue-flip>
                </div>

                <table>
                    <tr>
                        <td class="coord">
                            <span class="coord" :style="r.copy ? 'text-decoration: line-through;' : ''">{{ r.coord
                                }}</span>
                        </td>
                        <td class="tempo">
                            <span v-if="!r.copy">{{ r.tempo }} </span>
                            <span class="text-tempo" v-else>
                                <countdown :time="r.time * 1000" :transform="transform">
                                    <template slot-scope="props">
                                        {{ props.minutes }}:{{ props.seconds }}</template>
                                </countdown>
                            </span>
                        </td>
                        <td class="copy" v-if="!velocity">
                            <!-- <td class="copy" v-if="!velocity"> -->
                            <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="r.copy">
                                <template v-slot:front>
                                    <div class="btn" @click="getCopy(r, r.id)">
                                        {{ $t('missao.copiar') }}
                                    </div>
                                </template>
                                <template v-slot:back>
                                    <div class="btn" @click="getCopy(r, r.id)">=)</div>
                                </template>
                            </vue-flip>
                        </td>
                        <td v-else>
                            <!--  <td v-else> -->
                            <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="r.copy">
                                <template v-slot:front>
                                    <div class="btn" @click="getCopyVelocity(r, r.id)">
                                        {{ $t('missao.Abrir') }}
                                    </div>
                                </template>
                                <template v-slot:back>
                                    <div class="btn" @click="getCopyVelocity(r, r.id)">=)</div>
                                </template>
                            </vue-flip>
                        </td>
                    </tr>
                </table>

                <hr v-if="k % 3 === 2" />
            </li>
        </ul>
        <vue-ads-pagination :total-items="result.length" :max-visible-pages="3" :page="page" :items-per-page="perpage"
            @page-change="pageChange">
            <template slot-scope="props" v-if="true">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ props.start }}
                </div>
            </template>
            <template slot="buttons" slot-scope="props">
                <vue-ads-page-button v-for="(button, key) in props.buttons" :key="key" v-bind="button"
                    @page-change="pageChange(button)" />
            </template>
        </vue-ads-pagination>
        <Missao ref="missao" @setDados="getList" @setEmpty="getEmpty" @setSaida="setSaida" @setBotao="setBotao"
            @setType="setType" />
    </div>
</template>

<script>
import { registerPlugin } from '@capacitor/core'
import axios from 'axios'
import VueFlip from 'vue-flip'
import Countdown from '@chenfengyuan/vue-countdown'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-ads-pagination/dist/vue-ads-pagination.css'
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination'
import Mapa from '../components/Mapa'
import Missao from '../components/MissaoList'
import City from '../components/City'
//import moment from 'moment'
import moment from 'moment-timezone'
import Termina from '../components/Termina.vue'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { Toast } from '@capacitor/toast'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import SelectCity from '../components/Select'
import { getAnalytics, logEvent } from 'firebase/analytics'
import HomeRemote from '../components/HomeRemote.vue'
import Nota from '../components/Nota'
import Loading from '../components/Loading'
import { Device } from '@capacitor/device'

export default {
    name: 'App',
    props: ['remote'],
    components: {
        Countdown,
        VueFlip,
        VueAdsPagination,
        VueAdsPageButton,
        Mapa,
        Missao,
        Termina,
        SelectCity,
        HomeRemote,
        Nota,
        Loading
    },
    computed: {
        resultPage() {
            return this.result.slice(
                this.page * this.perpage,
                this.page * this.perpage + this.perpage
            )
        }
    },
    data() {
        return {
            text: '',
            result: '',
            results: [],
            list: [],
            select: '',
            loading: false,
            page: 0,
            total: 0,
            perpage: 21,
            active: false,
            activeMapa: false,
            gpx: '',
            textSelect: {},
            flag: {
                us: require('../assets/bandeiras/usa.png'),
                ca: require('../assets/bandeiras/can.png'),
                gb: require('../assets/bandeiras/ing.png'),
                sg: require('../assets/bandeiras/sin.png'),
                au: require('../assets/bandeiras/aus.png'),
            },
            saida: '',
            botao: false,
            velocity: false,
            type: '',
            end: false,
            lcl: 'us',
            cod: '',
            platform: 'web'
        }
    },
    async mounted() {
        const { platform } = await Device.getInfo()
        this.cod = this.$route.params.id
        this.platform = platform
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Missões'
        })

        document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`
        this.end = false
        if (localStorage.getItem('_result')) {
            this.active = true
            this.page = +localStorage.getItem('_page') || 0
            this.textSelect = JSON.parse(localStorage.getItem('_header'))
            this.result = JSON.parse(localStorage.getItem('_result')).map(i => {
                if (i.copy) {
                    i.time = 0
                }
                return i
            })
        }
        if (localStorage.getItem('_velocity')?.length) {
            this.velocity =
                localStorage.getItem('_velocity') === 'true' ? true : false
        } else {
            this.velocity = false
        }
        //this.getListCoords(1)
    },
    methods: {
        setCity(item) {
            //this.loading = true
            this.lcl = item.slug
            this.$refs.missao.setCity(item)
        },
        getEnd(e) {
            this.end = e
        },
        getCity(city) {
            return City.filter(i => i.slug === city)[0]
        },
        setType(e) {
            this.type = e
        },
        leftPad(value, totalWidth, paddingChar) {
            var length = totalWidth - value.toString().length + 1
            return Array(length).join(paddingChar || '0') + value
        },
        getSrc(i) {
            try {
                if (this.type === 'stardust') {
                    return require('../assets/item/stardust_painted.png')
                } else if (this.type === 'itens') {
                    let is
                    const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Items/'
                    if (i.value === '1') {
                        is = 'pokeball_sprite.png'
                        return url + is
                    } else if (i.value === '2') {
                        is = 'greatball_sprite.png'
                        return url + is
                    } else if (i.value === '3') {
                        is = 'ultraball_sprite.png'
                        return url + is
                    } else if (i.value2) {
                        return i.value2
                    } else {
                        is = `Item_${this.leftPad(i.value, 4)}.png`
                        return url + is
                    }
                } else if (this.type === 'mega') {
                    let m
                    if (i.value2) {
                        return i.value2
                    } else {
                        m = `${this.leftPad(i.value, 3)}_51`
                        const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/'
                        return `${url}pokemon_icon_${m}.png`
                    }
                } else {
                    let v
                    if (i.value2) {
                        v = `${this.leftPad(i.value2, 3)}_00`
                    } else if (i.value5) {
                        v = `${this.leftPad(i.value, 3)}_${i.value5}`
                    } else
                        if (i.value) {
                            let nn
                            if (i.title.texto.match(/Alola/)) {
                                nn = '.fALOLA'
                            } else if (i.title.texto.match(/Outono/)) {
                                nn = '.fAUTUMN'
                            } else if (i.title.texto.match(/Primavera/)) {
                                nn = '.fSPRING'
                            } else if (i.title.texto.match(/Verão/)) {
                                nn = '.fSUMMER'
                            } else if (i.title.texto.match(/Inverno/)) {
                                nn = '.fWINTER'
                            }
                            else if (i.title.texto.match(/Galarian/)) {
                                nn = '.fGALARIAN'
                            } else {
                                if (i.value == 327) {
                                    nn = '.f00'
                                } else if (i.value == 412) {
                                    nn = '.fBURMY_PLANT'
                                }
                                else if (i.value == 412) {
                                    nn = '.fBURMY_PLANT'
                                }
                                else {
                                    nn = ''
                                }
                            }
                            v = i.value + nn
                        }
                    const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/Addressable%20Assets/'
                    if (!i.value4) {
                        return `${url}pm${v}${i.shiny ? '.s' : ''}.icon.png`
                    } else {
                        return i.value4
                    }
                }
            } catch (e) {
                return require('../assets/pokemon/0.png')
            }
        },
        selectVelocity() {
            localStorage.setItem('_velocity', !this.velocity)
            this.velocity = !this.velocity
        },
        getCopyVelocity(r, key) {
            //r.copy = !r.copy
            const cache = JSON.parse(localStorage.getItem('_result'))
            cache.map((i, k) => {
                if (k === key) {
                    i.copy = !r.copy
                }
                return i
            })
            localStorage.setItem('_result', JSON.stringify(cache))
            if (!r.copy) {
                window.open(`https://pk.md/${r.coord}`, '_blank')
            }
        },
        getListUpdate() {
            this.page = 0
            this.getListCoords(1)
        },
        async getListCoords(page) {
            this.loading = true
            const list = await axios(`${this.$url}/missoes`, {
                params: {
                    page
                }
            })
            this.list = list.data.data
            this.total = list.data.total
            this.loading = false
        },
        setSaida(e) {
            this.saida = e
        },
        setBotao(e) {
            this.botao = e
        },
        copy3set(e) {
            this.$copyText(e).then(
                () => null,
                () => alert('Erro')
            )
        },
        setSaidaCopy() {
            this.$copyText(this.saida).then(
                () => null,
                () => alert('Erro')
            )
        },
        pageChange(page) {
            this.page = page.page
            this.getListCoords(page.page + 1)
            localStorage.setItem('_page', page.page)
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },

        getCopy(r, key) {
            //r.copy = !r.copy
            const cache = JSON.parse(localStorage.getItem('_result'))
            cache.map((i, k) => {
                if (k === key) {
                    i.copy = !r.copy
                }
                return i
            })
            localStorage.setItem('_result', JSON.stringify(cache))
            if (!r.copy) {
                this.$copyText(r.coord).then(
                    () => null,
                    () => alert('Erro')
                )
            }
        },
        transform(props) {
            Object.entries(props).forEach(([key, value]) => {
                const digits = value < 10 ? `0${value}` : value
                props[key] = `${digits}`
            })

            return props
        },
        convert(num, text) {
            switch (text) {
                case 'seg':
                    return num
                case 'sec':
                    return num
                case 'min':
                    return num * 60
                default:
                    return num
            }
        },
        getEmpty(e = '') {
            this.text = ''
            this.select = {}
            this.select = {val: ''}
            this.result = []
            this.gpx = ''
            this.activeMapa = false
            this.textSelect = {}
            localStorage.removeItem('_header')
            if (this.$route.path !== '/' && this.$route.meta.lang === 'pt') {
                this.$router.push('/')
            }
            if (this.$route.path !== '/en' && this.$route.meta.lang === 'en') {
                this.$router.push('/')
            }
            if (this.$route.path !== '/es' && this.$route.meta.lang === 'es') {
                this.$router.push('/')
            }
            if (e === 'initial') {
                this.active = true
            } else {
                this.active = false
            }
            if (localStorage.getItem('_result')) {
                localStorage.removeItem('_result')
            }
        },
        verAndOpenFile() {
            this.gpx = `<?xml version="1.0" encoding="UTF-8"?>
            <gpx version="1.1" creator="Só Coordenadas | Solo coordenadas | Only Coordinate | https://coordsgo.com">
              <trk>
              ${this.result
                    .map(i => {
                        let coord = i.coord.trim().split(',')
                        return `<trkpt lat="${coord[0]}" lon="${coord[1]}">
                  </trkpt>`
                    })
                    .join('')}</trk></gpx>`
        },
        async createAndOpenFile() {
            let gpx = `<?xml version="1.0" encoding="UTF-8"?>
            <gpx version="1.1" creator="Só Coordenadas | Solo coordenadas | Only Coordinate | https://coordsgo.com">
              ${this.result
                    .map(i => {
                        let coord = i.coord.trim().split(',')
                        return `<wpt lat="${coord[0]}" lon="${coord[1]}"></wpt>`
                    })
                    .join('')}</gpx>`
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(
                new Blob([gpx], { type: 'text/json' })
            )
            a.download = `coordsgo_gpx${Math.floor(Date.now() / 1000)}.gpx`

            document.body.appendChild(a)
            document.body.removeChild(a)
            try {
                await Filesystem.mkdir({
                    path: 'Download/CoordsGO',
                    directory: Directory.ExternalStorage,
                    recursive: false,
                })
            }
            catch (e) { console.log(e) }
            await Filesystem.writeFile({
                path: `Download/CoordsGO/${a.download}`,
                data: gpx,
                directory: Directory.ExternalStorage,
                encoding: Encoding.UTF8,
            })
            await Toast.show({
                text: 'Arquivo gravado em Download/CoordsGO!',
            })
            //a.click()

            //document.body.removeChild(a)
        },
        async getListCoordsRouter(id) {
            const list = await axios.get(`${this.$url}/missoes/${id}`)
            this.setListCoordList(list.data)
        },
        async setListCoordList(list) {
            this.active = true
            this.getList(list.content)
            this.select = list.id
            await axios.put(`${this.$url}/missoes/${this.select}`, {
                click: Number(list.click) + 1
            })
            this.gpx = ''
            this.activeMapa = false
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        sendWaths(id, type) {
            let coords
            if (this.$route.meta.lang === 'en') {
                coords = `https://coordsgo.com/missao/${id}/link`
                if (type === 'copy') {
                    this.$copyText(coords).then(
                        () => null,
                        () => alert('Erro')
                    )
                    return this.$toasted.show('Copied!', {
                        theme: 'toasted-primary',
                        position: 'bottom-right',
                        duration: 5000
                    })
                }
                window.open(
                    `https://api.whatsapp.com/send?text=${window.encodeURIComponent(
                        `https://coordsgo.com/missao/${id}/link`
                    )}`,
                    '_blank'
                )
            } else if (this.$route.meta.lang === 'es') {
                coords = `https://coordsgo.com/missao/${id}/link`
                if (type === 'copy') {
                    this.$copyText(coords).then(
                        () => null,
                        () => alert('Erro')
                    )
                    return this.$toasted.show('Copiado!', {
                        theme: 'toasted-primary',
                        position: 'bottom-right',
                        duration: 5000
                    })
                }
                window.open(
                    `https://api.whatsapp.com/send?text=${window.encodeURIComponent(
                        `https://coordsgo.com/missao/${id}/link`
                    )}`,
                    '_blank'
                )
            } else {
                coords = `https://coordsgo.com/missao/${id}/link`
                if (type === 'copy') {
                    this.$copyText(coords).then(
                        () => null,
                        () => alert('Erro')
                    )
                    return this.$toasted.show('Copiado!', {
                        theme: 'toasted-primary',
                        position: 'bottom-right',
                        duration: 5000
                    })
                }
                window.open(
                    `https://api.whatsapp.com/send?text=${window.encodeURIComponent(
                        `https://coordsgo.com/missao/${id}/link`
                    )}`,
                    '_blank'
                )
            }
        },
        getImage(e) {
            e.target.src = require('../assets/pokemon/0.png')
        },
        async setListCoord() {
            //this.$router.push(`/coordinates/${list.id}`)
            this.active = true
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
            /*  this.getList(list.content)
            this.select = list.id
            await axios.put(
              `${this.$url}/missoes/${this.select}`,
              {
                click: Number(list.click) + 1
              }
            ) */
            //this.gpx = ''
            //this.activeMapa = false
            //document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        moment,
        async setListConvert() {
            this.select = ''
            this.gpx = ''
            this.activeMapa = false
            await axios.post(`${this.$url}/missoesold`, {
                text: this.text
            })
            this.getList(this.text)
        },
        async getList(text) {
            //this.textSelect = {}
            this.result = ''
            this.select = ''

            /* let dadostext = await axios.get(
              `${this.$url}/missoes-text/get?text=${text.conditions_string}`
            )
            dadostext = dadostext.data
            if (this.$i18n.locale === 'pt-br') {
              dadostext = dadostext.text_pt
            } else if (this.$i18n.locale === 'es-es') {
              dadostext = dadostext.text_es
            } else if (this.$i18n.locale === 'en-us') {
              dadostext = dadostext.text
            }

            if (!dadostext) {
              dadostext = text.conditions_string
            } */
            this.end = false
            this.page = 0
            localStorage.setItem('_page', 0)
            this.textSelect = text.headers
                ? {
                    missao: text.headers.missoes.filter(
                        i => i.texto === text.conditions_string
                    ).length
                        ? text.headers.missoes.filter(
                            i => i.texto === text.conditions_string
                        )
                        : '',
                    title: {
                        texto_en: text.headers.texto_en,
                        texto_es: text.headers.texto_es,
                        texto_pt: text.headers.texto_pt,
                        texto: text.headers.texto
                    },
                    id: text.id,
                    value: text.headers.value ? text.headers.value : '',
                    value2: text.headers.value2 ? text.headers.value2 : '',
                    value3: text.headers.value3 ? text.headers.value3 : '',
                    value4: text.headers.value4 ? text.headers.value4 : '',
                    value5: text.headers.value5 ? text.headers.value5 : '',
                    shiny: text.headers.shiny ? text.headers.shiny : '',
                    location: text.lcl,
                    datefim: text.date,
                    varios: text.varios || false
                }
                : ''
            localStorage.setItem('_header', JSON.stringify(this.textSelect))
            document.title = `${this.$i18n.locale} CoordsGo.com - ${text.rewards_string} - ${text.conditions_string}`
            if (text.content) {
                const results = await text.content.matchAll(
                    // eslint-disable-next-line no-useless-escape
                    /(-?\d+(?![:|\/]).\d+, ?-?\d+(?![:|\/]).\d+)(:blank:)?(.+?(\d+\w+)?(\d+ ?)(\w{3}))?/g
                )
                this.result = Array.from(results).map((i, k) => {
                    return {
                        coord: i[1],
                        copy: false,
                        copy3: false,
                        tempo: i[5] ? `${i[5]}${i[6]}` : null,
                        time: i[5] ? Number(this.convert(i[5], i[6])) : 0,
                        id: k,
                        varios: text.varios || false
                    }
                })
                const analytics = getAnalytics(this.$fire)
                logEvent(analytics, 'gerador_quest', {
                    content_type: 'list'
                })
                const rst =  JSON.stringify(this.result)
                localStorage.setItem('_result', rst)
                localStorage.setItem('_cod', text.id)
                this.cod = text.id
                await axios.post(`${this.$url}/quest/simples`, {
                  quest_id: text.id,
                  json: rst
                })
                this.active = true
                if (
                    this.$route.name === 'missaoIdLink' ||
                    this.$route.name === 'missaoIdLinkEs' ||
                    this.$route.name === 'missaoIdLinkEn'
                ) {
                    if (this.$route.meta.lang === 'pt') {
                        this.$router.push(`/missao/${text.id}`)
                    }
                    if (this.$route.meta.lang === 'en') {
                        this.$router.push(`/missao/${text.id}`)
                    }
                    if (this.$route.meta.lang === 'es') {
                        this.$router.push(`/missao/${text.id}`)
                    }
                }
                if (
                    text.id != this.$route.params.id &&
                    this.$route.meta.lang === 'pt'
                ) {
                    this.$router.push(`/missao/${text.id}`)
                }
                if (
                    text.id != this.$route.params.id &&
                    this.$route.meta.lang === 'en'
                ) {
                    this.$router.push(`/missao/${text.id}`)
                }
                if (
                    text.id != this.$route.params.id &&
                    this.$route.meta.lang === 'es'
                ) {
                    this.$router.push(`/missao/${text.id}`)
                }
                setTimeout(() => {
                    this.loading = false
                    this.page = 0
                    this.hapticsImpactMedium()
                    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }, 300)
            }

        },
        async hapticsImpactMedium() {
            await Haptics.impact({ style: ImpactStyle.Medium })
        },
        async setWindow() {
          let cod = this.cod ? this.cod : localStorage.getItem('_cod')
            const FloatingWindowPlugin = registerPlugin('FloatingWindowPlugin')
            window.FloatingWindowPlugin = FloatingWindowPlugin
            await FloatingWindowPlugin.showFloatingWindow({url: `https://coordsgo.com/quest?id=${cod}`})
        }
    }
}
</script>

<style lang="scss">
.missao {
    margin-top: 10px;
}

ul.home {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    line-height: 30px;
}

li a {
    color: #2c3e50;
    text-decoration: none;
}

.coord {
    width: 200px;
}

textarea {
    width: 100%;
    margin: 10px auto;
    border: 2px solid #673bb7;
    padding: 10px;
    box-sizing: border-box;
}

table {
    margin: 0 auto;
    width: 100%;
}

td {
    text-align: left;
}

td:nth-child(2) {
    width: 70px;
}

hr {
    border-bottom: 1px dashed #ccc;
    border-style: dashed;
    width: 300px;
}

.list-corpo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button {
    font-size: 18px;
    padding: 5px;
    margin: 0 5px;
}

.whats {
    margin-top: 4px;
}

.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

.btn-content {
    margin: 0 auto;
}

.btn-container {
    flex: 1;
    margin: 0 auto 15px auto;
}

.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.text-tempo {
    background-color: #673bb7;
    color: #fff;
    font-size: 14px;
    padding: 4px;
}

.btn-down {
    background: #673bb7;
    color: #fff;
    text-decoration: none;
    height: 50px;
    flex: 1;
    border-radius: 5px;
}

.btn-yellow {
    background-color: #ffc107;
    color: #fff;
    text-decoration: none;
    height: 50px;
    flex: 1;
    border-radius: 5px;
}

.btn-container {
    flex: 1;
    margin: 0 3px 15px 3px;
}

.list {
    padding: 5px;
    box-sizing: border-box;
    text-align: initial;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    flex: 1;
}

.list small {
    font-size: 12px;
    font-weight: normal;
}

.date {
    font-size: 10px !important;
}

.list.active {
    background: rgb(103 59 183 / 21%);
}

.list-content {
    cursor: pointer;
    font-weight: normal;
    font-size: 13px;
}

.list-content .btn-down {
    width: 120px;
    height: 35px;
    border-radius: 5px;
}

h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
}

.vue-ads-leading-loose {
    display: none !important;
}

.vue-ads-justify-end {
    justify-content: flex-start !important;
}

.vue-ads-bg-teal-500 {
    background-color: #673bb7 !important;
}

.vue-ads-flex {
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    margin: 10px 0 !important;
}

.vue-ads-flex-grow {
    flex-grow: 0 !important;
    flex: 1 !important;
    justify-content: space-between !important;
}

.vue-ads-text-xs {
    font-size: 1rem !important;
}

.yellow {
    background-color: #ffc107 !important;
}

.logo {
    background: #673bb7;
    width: 40px;
    height: 40px;
    font-size: 32px;
    color: #fff;
    margin-left: 15px;
}

.logo .c {
    position: relative;
    top: -12px;
    left: -4px;
}
</style>

<style lang="scss" scoped>
.btn-copy {
    color: #fff;
    background: #673ab7;
    width: 110px !important;
    margin: 0 auto;
    text-align: center;
    height: 25px !important;
    font-size: 12px;
}

.btn-velocity {
    color: #fff;
    background: #673ab7;
    width: 130px !important;
    margin: 0 auto;
    text-align: center;
    height: 25px !important;
    font-size: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.cards {
    border-radius: 5px;
    font-size: 14px;
}

.flag {
    height: 30px;
}

.igg {
    max-height: 60px;
    max-width: 60px;
}
</style>
