<template>
    <div style="position: relative">

        <div class="list-corpo">
            <li class="list list-card">
                <div class="card-content">
                    <div class="card-card">
                        <div class="card-cont">
                            <div class="shiny" v-if="active === 'pokemon' && l.shiny">
                                Shiny
                            </div>
                            <div class="nome">
                                {{
                                $i18n.locale === 'pt-br'
                                ? l.texto_pt || l.texto
                                : $i18n.locale === 'es-es'
                                ? l.texto_es || l.texto
                                : l.texto
                                }}
                            </div>
                        </div>
                        <div class="small">
                            <small>{{
                            $i18n.locale === 'pt-br'
                            ? l.quest.texto_pt || l.quest.texto
                            : $i18n.locale === 'es-es'
                            ? l.quest.texto_es || l.quest.texto
                            : l.quest.texto_en
                            }}</small>
                            <small v-if="lm"> - {{ lm }}</small>
                        </div>
                    </div>
                    <div>
                        <div class="apagar" @click="apagarselect(km)"><i class="fa fa-trash"></i></div>
                    </div>
                </div>
            </li>
        </div>
    </div>
</template>

<script>
import dados from './Pogo/shiny'

export default {
    name: 'ListMini',
    props: [
        'l',
        'lm',
        'km',
        'active',
        'apagarselect'
    ],
    data: () => ({
        send: false
    }),
    methods: {
        setShiny(dex) {
            return dados
                .filter(i => i.dex === Number(dex) && i.dex !== 618 && i.dex !== 562)
                .some(i => i.shiny_released || i.released_date)
        },
    }
}
</script>

<style lang="scss" scoped>
.list-corpo {
    //border: 1px solid #ccc;
    box-shadow: 0 0 3px hsl(0deg 0% 100% / 54%);
    margin: 7px 0;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    a {
        width: 20px;
        margin: 0 10px;
    }

    .event,
    .new {
        background-color: #673bb7;
        text-transform: uppercase;
    }

    &.event-active {
        background-color: #f0ebf9;
    }

    &.active {
        background: #673bb7;
        color: #fff;

        a {
            color: #fff;
        }
    }
}

.list small {
    font-size: 10px;
}

.event,
.new {
    content: '50%';
    position: absolute;
    bottom: 5px;
    text-align: center;
    padding: 1px 0;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px;
    text-shadow: 0 0 3px rgb(0 0 0 / 50%);
    //transform: rotate(45deg);
    box-shadow: 0 0 3px hsl(0deg 0% 100% / 54%);
    width: 42px;
    font-size: 7px;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 8px;
}

.card-content {
    display: flex;
    justify-content: space-between;

    align-items: center;
    padding-bottom: 3px;

    .card-img {
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: center;
    }

    .card-card {
        display: flex;
        flex-direction: column;
        margin-left: 5px;

        div {
            height: 15px;
        }

        .small {
            position: relative;
            top: -12px;
        }
    }
}

.list {
    border: 1px solid #ccc;
    height: 35px;
    border-radius: 5px 0 0 5px;
}

.compartilhar {
    height: 63px;
    display: flex;
    flex-direction: column;

    .copy {
        flex: 1;
        background-color: #fff;
        //border-top-right-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-left: 0;
        border-bottom: 0;
        border-radius: 0 5px 0 0;

        .copiar {
            width: 32px;

            i {
                font-size: 15px;
                color: #673bb7;
            }
        }
    }

    .what {
        flex: 1;
        background-color: #fff;
        border-bottom-right-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-left: 0;
        border-radius: 0 5px 5px 0;

        .whats {
            img {
                height: 18px;
            }
        }
    }
}

.card-cont {
    display: flex;
    align-items: center;

    .shiny {
        background: #ffc107;
        color: rgb(255, 255, 255);
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 0 5px;
        border-radius: 5px;
        text-transform: uppercase;
        margin-right: 5px;
        font-size: 8px;
        height: 14px !important;
    }

    .nome {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.apagar {
    width: 70px;
    color: #9c27b0;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 5px;
}

.card-img-send {
    //position: absolute;
}
</style>

<style lang="scss">
.item {
    display: block;
    animation: yAxis 2s forwards cubic-bezier(1.000, 0.440, 0.840, 0.165);

    &:before {
        animation: yAxis 1s alternate forwards cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
}



@keyframes mymove {
    from {
        top: 0px;
    }

    to {
        top: 50px;
    }
}

/* .item {
    display: block;
    animation: xAxis 1s forwards cubic-bezier(1.000, 0.440, 0.840, 0.165);

    &:before {
        animation: yAxis 1s alternate forwards cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
} */

.shake {
    animation: shakeCart .4s ease-in-out forwards;
}

@keyframes xAxis {
    100% {
        transform: translateX(calc(50vw - 5px));
    }
}

@keyframes yAxis {
    100% {
        transform: translateY(calc(50vh + 75px));
    }
}

@keyframes shakeCart {
    25% {
        transform: translateX(6px)
    }

    50% {
        transform: translateX(-4px);
    }

    75% {
        transform: translateX(2px);
    }

    100% {
        transform: translateX(0);
    }
}
</style>