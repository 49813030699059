<template>
    <div class="w-auto overflow-x-scroll overflow-y-hidden whitespace-nowrap grid grid-cols-auto"
        :class="{ 'flex': raid }">
        <div class="menu relative w-36 mx-0 mr-2.5 p-0 cursor-pointer border border-solid border-cinza rounded-md"
            v-for="(l, k) in local" :key="k"
            :class="[{ 'bg-roxo text-white' : l.select }, { breve: l.breve }, { newm: !active }]"
            @click.prevent="l.breve ? '' : getItem(l)">
            <div class="flex justify-center items-center text-xs text-center pt-1 pb-0 m-0 font-bold">
                <img class="mr-1 h-6" :src="l.img" />
                {{
                $i18n.locale === 'pt-br'
                ? l.title_pt
                : $i18n.locale === 'es-es'
                ? l.title_es
                : l.title
                }}

            </div>
            <div class="text-xs text-center pt-1 pb-0 m-0 font-bold">{{ l.date }}</div>
            <div v-if="active">
                <div v-if="l.countHour && l.countHour.h >= 20 && l.countHour.h <= 24">
                    <div class="text-s pb-2.5">
                        {{ $t('missao.expirado') }}
                    </div>
                </div>

                <div v-else>
                    <div class="text-s pb-2.5" v-if="l.count">
                        {{ $t('missao.expira') }} <i class="fa fa-clock" />
                        <strong> {{ l.count }}</strong>
                    </div>
                    <div class="text-s pb-2.5" v-if="!l.count">
                        {{ $t('missao.expira') }} <i class="fa fa-clock" />
                        <strong> 0h 0m 0s</strong>
                    </div>
                </div>
                <div class="event" v-if="l.new">{{ $t('missao.novo') }}</div>
                <div class="event" v-if="l.breve">{{ $t('missao.breve') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import 'moment-timezone'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import city from './City'

export default {
    name: 'Select',
    props: {
        active: {
            default: true
        },
        all: {
            default: false
        },
        raid: {
            default: false
        }
    },
    data: () => ({
        location: city
    }),
    computed: {
        local() {
            if (!this.all) {
                return this.location.filter(i => this.raid ? i.raid : i.status)
            } return this.location.filter(i => this.raid ? i.raid : i.status)
        }
    },
    methods: {
        getContador(epoch) {
            var hours = epoch / 3600,
                minutes = (hours % 1) * 60,
                seconds = (minutes % 1) * 60
            return `${Math.floor(hours)}h ${Math.floor(minutes)}m ${Math.round(
                seconds
            )}s`
        },
        getContadorHora(epoch) {
            var hours = epoch / 3600,
                minutes = (hours % 1) * 60,
                seconds = (minutes % 1) * 60
            return {
                h: Math.floor(hours),
                m: Math.floor(minutes),
                s: Math.round(seconds),
                st: hours >= 21 && hours <= 23
            }
        },
        getHoras() {
            this.location.map(i => {
                var dateZoneUnix = moment().tz(i.timezone)
                var start = dateZoneUnix.unix()
                var end = dateZoneUnix.endOf('day').unix()
                var res = end - start
                i.count = this.getContador(res)
                i.countHour = this.getContadorHora(res)
                i.date = moment()
                    .tz(i.timezone)
                    .format('HH:mm:ss ‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎ ‏‏‎‎DD/MM/YY')
                return i
            })
        },
        getItem(item) {
            this.location.map(i => {
                i.select = false
                return i
            })
            item.select = true
            localStorage.setItem('_city', JSON.stringify(item))
            this.$emit('setCity', item)
            this.hapticsImpactMedium()
        },
        async hapticsImpactMedium() {
            await Haptics.impact({ style: ImpactStyle.Medium })
        }
    },
    mounted() {
        setInterval(this.getHoras, 1000)
        if (localStorage.getItem('_city')) {
            let city = JSON.parse(localStorage.getItem('_city'))
            this.location.map(i => {
                if (i.title === city.title) {
                    i.select = true
                } else {
                    i.select = false
                }
                return i
            })
            this.$emit('setCity', city)
        }
    }
}
</script>

<style lang="scss" scoped>
.event {
    content: '50%';
    position: absolute;
    top: 5px;
    right: -10px;
    text-align: center;
    padding: 1px 0;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px;
    text-shadow: 0 0 3px rgb(0 0 0 / 50%);
    transform: rotate(45deg);
    box-shadow: 0 0 3px hsl(0deg 0% 100% / 54%);
    width: 42px;
    font-size: 7px;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #673bb7;
    text-transform: uppercase;
}

.breve {
    background: #eee;
}

.flex {
    display: flex
}

.newm {
    padding: 10px 0;
}
</style>
