<template>
    <div>
        <!-- <SelectCity @setCity="setCity" /> -->
        <ul class="menu">
            <li :class="{ actived: active === 'pokemon' }" @click.prevent="setMenu('pokemon')">
                <img :src="require('../assets/item/image.png')" style="height: 35px" />
            </li>
            <li :class="{ actived: active === 'stardust' }" @click.prevent="setMenu('stardust')">
                <img :src="require('../assets/item/stardust_painted.png')" style="height: 30px" />
            </li>
            <li :class="{ actived: active === 'itens' }" @click.prevent="setMenu('itens')">
                <img :src="require('../assets/item/Item_1301.png')" style="height: 25px" />
            </li>
            <li :class="{ actived: active === 'mega' }" @click.prevent="setMenu('mega')">
                <img :src="require('../assets/item/tx_raid_coin_mega.png')" style="height: 25px" />
            </li>
        </ul>
        <div>
            <div class="cart-visualizar animate-open" :class="{'animate-hide': !visualizar }" v-if="visualizar">
                <div class="close" @click="fecharSelectMulti">
                    <img class="close-img" src="../assets/close_icon.png" />
                </div>
                <div>
                    <div v-for="(lm, km) in selectMulti" :key="km">
                        <list-mini :active="active" :l="lm" :lm="lm.label" :km="km" :apagarselect="apagarselect" />
                    </div>
                </div>
            </div>
            <div v-if="selectMulti.length" class="cart" ref="cart" :class="{shake}">
                <div class="count" @click="visualizarSelectMulti" v-if="!visualizar">{{ selectMulti.length}}</div>
                <div class="count" @click="gerar" v-else>Gerar</div>
                <div class="apagar" @click="apagarselectMulti"><i class="fa fa-trash"></i></div>
            </div>
            <Loading :active="loading" />
            <div v-if="!loading">
                <div class="content-input">
                    <input class="search" type="text" v-model="search" :placeholder="$t('missao.pesquisar')" />
                    <div class="limpar" @click.prevent="search = ''">
                        <i class="fa fa-trash" />
                    </div>
                </div>
                <NotaShiny v-if="active === 'pokemon'" />
                <div v-for="(l, k) in listFilter" :key="k">
                    <div v-for="(lc, kc) in $i18n.locale === 'pt-br'
                    ? l.missoes_pt || l.missoes
                    : $i18n.locale === 'es-es'
                    ? l.missoes_es || l.missoes
                    : l.missoes" :key="kc">
                        <div v-if="
                          $i18n.locale === 'pt-br'
                            ? !lc.missoes_pt
                            : $i18n.locale === 'es-es'
                            ? !lc.missoes_es
                            : !lc.missoes
                        ">
                            <list ref="list" :l="l" :lc="lc" :lcl="lcl" lm="" :select="select" :getDados="getDados"
                                :sendWaths="sendWaths" :active="active" :onLongPressStart="onLongPressStart" />
                        </div>
                        <div v-else>
                            <div v-for="(lm, km) in $i18n.locale === 'pt-br'
                            ? lc.missoes_pt
                            : $i18n.locale === 'es-es'
                            ? lc.missoes_es
                            : lc.missoes" :key="km">
                                <list :l="l" :lc="lc" :lcl="lcl" :lm="lm.label" :km="km" :select="select"
                                    :getDados="getDados" :sendWaths="sendWaths" :active="active"
                                    :onLongPressStart="onLongPressStart" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from '../components/Loading'
import List from './List'
import ListMini from './ListMini'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import NotaShiny from '../components/NotaShiny'

export default {
    components: { Loading, List, ListMini, NotaShiny },
    data: () => ({
        list: [],
        active: 'pokemon',
        loading: false,
        type: 7,
        select: { val: '' },
        content: '',
        lcl: 'us',
        search: '',
        saida: '',
        getBanco: true,
        selectMulti: [],
        shake: false,
        visualizar: false
    }),
    computed: {
        listFilter() {
            if (this.search) {
                const row = this.list.filter(i => {
                    return i.texto.toLowerCase().includes(this.search.toLowerCase())
                })
                return row
            }
            return this.list
        }
    },
    methods: {
        fecharSelectMulti() {
            this.visualizar = false
        },
        visualizarSelectMulti() {
            this.visualizar = !this.visualizar
        },
        onLongPressStart(a, b, c, d) {
            this.shake = true
            const select = JSON.parse(JSON.stringify(a))
            select.quest = JSON.parse(JSON.stringify(b))
            select.questtext = c
            select.k = d
            setTimeout(() => {
                this.shake = false
                if (!this.selectMulti.length) {
                    this.selectMulti.push(select)
                } else {
                    const check = this.selectMulti.filter(s => {
                        return s.texto === select.texto && s.quest.texto === b.texto
                    })
                    if (!check.length) {
                        if (this.selectMulti.length <= 4) {
                            this.selectMulti.push(select)
                        } else {
                            this.$toast.warning('É possivel somente selecionar 5', {
                                position: 'bottom'
                            })
                        }
                    } else {
                        this.$toast.error('Esse já foi selecionado', {
                            position: 'bottom'
                        })
                    }
                }

                this.hapticsImpactMedium()
            }, 500)
        },
        async setDados(dados, header, date) {
            window.header = header
            //let id = dados.data.id
            let headers = header.map(i => `${i?.texto}`).toString()
            //let count = await this.groupByKey(dados, 'conditions_string')
            //count = Object.keys(count).length
            /* if (count > 1) {
                let filterText = lc.texto ? lc.texto : lc.missoes[k].texto
                dados = dados.filter(
                    i =>
                        i.conditions_string === filterText ||
                        i.rewards_string === filterText
                )
            } */
            var cc = dados.map(
                i => `${this.arendondar(i.lat, 6)},${this.arendondar(i.lng, 6)}`
            )
            var listaCC = []

            cc.forEach(i => {
                let x = i.split(',')
                if (x.length === 2) {
                    if (!isNaN(x[0]) && !isNaN(x[1])) {
                        listaCC.push(x)
                    }
                }
            })

            var listaFinal = []
            var distancias = []
            var tam = listaCC.length

            if (tam > 1) {
                var ccInicio = listaCC[0]
                listaCC.splice(0, 1)
                listaFinal.push(ccInicio)
                while (listaCC.length > 1) {
                    var dMin = 1000000000
                    var pos = -1
                    for (let i = 0; i < listaCC.length; i++) {
                        var dx = this.calcularDistancia(
                            ccInicio[0],
                            ccInicio[1],
                            listaCC[i][0],
                            listaCC[i][1]
                        )
                        if (dx < dMin) {
                            pos = i
                            dMin = dx
                        }
                    }
                    listaFinal.push(listaCC[pos])
                    distancias.push(dMin)
                    ccInicio = listaCC[pos]
                    listaCC.splice(pos, 1)
                }
                var coordenadas = []
                var tempos = []
                var penultimo = []
                for (let x = 0; x < listaFinal.length; x++) {
                    coordenadas.push(
                        `${this.arendondar(listaFinal[x][0], 6)},${this.arendondar(
                            listaFinal[x][1],
                            6
                        )}`
                    )
                    if (x === listaFinal.length - 1) {
                        penultimo = listaFinal[x]
                    }
                }
                var ultimo = []
                coordenadas.push(
                    `${this.arendondar(listaCC[0][0], 6)},${this.arendondar(
                        listaCC[0][1],
                        6
                    )}`
                )
                ultimo = listaCC[0]
                // eslint-disable-next-line no-unused-vars
                var distFinal = 0
                tempos.push('0 seg')
                distancias.forEach(x => {
                    distFinal += x
                    tempos.push(this.tempo(x))
                })
                var disRest = this.calcularDistancia(
                    penultimo[0],
                    penultimo[1],
                    ultimo[0],
                    ultimo[1]
                )
                distFinal += disRest
                tempos.push(this.tempo(disRest))
                var set = 1
                var calculoFinal = ''
                distFinal += disRest
                tempos.push(this.tempo(disRest))
                var countTag = 0
                const arrayTag = ['Ⓒ', 'ⓞ', 'ⓞ', 'ⓡ', 'ⓓ', 'ⓢ', 'Ⓖ', 'Ⓞ']
                var sizeTag = arrayTag.length
                for (let x = 1; x <= coordenadas.length; x++) {
                    if (x === 1) {
                        // eslint-disable-next-line no-irregular-whitespace
                        calculoFinal += `\nLugar: ${this.getLugar(this.lcl)}\nRecompensa: ${headers
                            }\nTotal: ${coordenadas.length
                            }\n【Ｃｏｏｒｄｓ ＧＯ】\n\nSᴇᴛ ${set}`
                        set++
                    }
                    calculoFinal += `\n${arrayTag[countTag]} ${coordenadas[x - 1]} - ${tempos[x - 1]
                        }`
                    if (x % 3 === 0 && x !== coordenadas.length) {
                        calculoFinal += `\n\nSᴇᴛ  ${set}`
                        set++
                    }
                    countTag = countTag === sizeTag - 1 ? 0 : countTag + 1
                }
                if (coordenadas.length !== 0) {
                    var opt1 =
                        '\n\nRota gerada em: Coords GO\nhttps://coordsgo.com/'
                    calculoFinal += `${opt1}`
                }
                var dist = 'Distância:'

                this.saida = `${calculoFinal}\n`
                this.$emit('setSaida', this.saida)
                this.$emit('setBotao', true)
            }
            if (dados.length) {
                this.content = `${dist} ${this.arendondar(
                    distFinal,
                    3
                )} km ${calculoFinal}\n`
                this.$emit('setDados', {
                    content: this.content,
                    rewards_string: dados[0].rewards_string,
                    conditions_string: dados[0].conditions_string,
                    lcl: dados,
                    id: null,
                    headers: {
                        texto: header.map(i => `${i.texto}`).toString(),
                        texto_en: header.map(i => `${i.texto}`).toString(),
                        texto_es: header.map(i => `${i.texto}`).toString(),
                        texto_pt: header.map(i => `${i.texto}`).toString(),
                        missoes: []
                    },
                    date,
                    varios: true
                })
                this.selectMulti = []
                this.visualizar = false
            } else {
                alert(this.$t('missao.alerta'))
                this.$parent.loading = false
            }
        },
        async gerar() {
            this.getBanco = true
            this.select = { val: '' }
            this.$parent.loading = true
            this.$emit('setEmpty')
            this.content = ''
            let dados = []
            let headers = []
            let date
            const promises = this.selectMulti.map(async (l) => {
                let data = []
                data.push(
                    this.type,
                    this.type === 3 ? l.value : 0,
                    this.type === 3 ? 0 : l.value
                )
                let lcl = this.lcl
                this.select = {
                    l,
                    lc: l.quest,
                    text: l.questtext,
                    val: `${lcl}-${l.value2 || l.value}-${l.questtext}`
                }
                let header = JSON.parse(JSON.stringify(l))
                delete header.base
                localStorage.setItem('_select', JSON.stringify(this.select))
                let quest = `quests[]=7,0,290&quests[]=${data.toString()}&time=${new Date().getTime()}&lc=${lcl}&header=${JSON.stringify(
                    header
                ).replace('#', '%23')}`
                let dadosv = await axios(`${this.$url}/miss/v2?${quest}`).catch(e => {
                    this.$parent.loading = false
                    alert(e)
                })
                headers.push({
                    "texto": l.texto, "value": l.value, "qtd": l.qtd, "quest": l.quest, "questtext": l.questtext, select: false, missoes: l.missoes

                })

                date = await dadosv.data.date
                /* await dadosv.data.quests.map(x => {
                    dados.push(x)
                    return x
                })

                if (k === (this.selectMulti.length - 1)) {
                    this.setDados(dados, headers, date)
                } */
                return dadosv

            })
            Promise.all([...promises]).then((values) => {
                values.map((d) => {
                    d.data.quests.map(x => {
                        dados.push(x)
                        return x
                    })
                })
                this.setDados(dados, headers, date)
            })


        },
        apagarselectMulti() {
            this.selectMulti = []
            this.visualizar = false
        },
        apagarselect(index) {
            this.selectMulti.splice(index, 1)
            if (!this.selectMulti.length) {
                this.visualizar = false
            }
        },
        async hapticsImpactMedium() {
            await Haptics.impact({ style: ImpactStyle.Medium })
        },
        setCity(item) {
            this.loading = true
            this.lcl = item.slug
            this.getList()
            setTimeout(() => {
                this.loading = false
            }, 300)
        },
        setMenu(valor) {
            this.active = valor
            this.search = ''
            localStorage.setItem('_item', valor)
            this.$emit('setType', valor)
            this.getList()
        },
        async sendWaths(type, l, lc, text, k) {
            this.getBanco = true
            this.select = { val: '' }
            this.$parent.loading = true
            let data = []
            this.$emit('setEmpty')
            this.content = ''
            data.push(
                this.type,
                this.type === 3 ? l.value : 0,
                this.type === 3 ? 0 : l.value
            )
            let lcl = this.lcl
            this.select = {
                l,
                lc,
                text,
                val: `${lcl}-${l.value}-${text}`
            }
            let header = JSON.parse(JSON.stringify(l))
            delete header.base
            localStorage.setItem('_select', JSON.stringify(this.select))
            let quest = `quests[]=7,0,290&quests[]=${data.toString()}&time=${new Date().getTime()}&lc=${lcl}&header=${JSON.stringify(
                header
            ).replace('#', '%23')}`
            let dados = await axios(`${this.$url}/miss/v2?${quest}`).catch(e => {
                this.$parent.loading = false
                alert(e)
            })
            if (this.lcl === 'tw') {
                this.$parent.loading = false
                return this.$emit('setDados', {
                    content: l.content,
                    rewards_string: l.labelReward,
                    conditions_string: l.labelTask,
                    lcl: this.lcl,
                    id: 0
                })
            }
            let id = dados.data.id
            dados = dados.data.quests
            let count = await this.groupByKey(dados, 'conditions_string')
            if (!count) {
                return
            }
            count = Object.keys(count).length
            if (count > 1) {
                let filterText = lc.texto ? lc.texto : lc.missoes[k].texto
                dados = dados.filter(
                    i =>
                        i.conditions_string === filterText ||
                        i.rewards_string === filterText
                )
            }
            var cc = dados.map(
                i => `${this.arendondar(i.lat, 6)},${this.arendondar(i.lng, 6)}`
            )
            var listaCC = []

            cc.forEach(i => {
                let x = i.split(',')
                if (x.length === 2) {
                    if (!isNaN(x[0]) && !isNaN(x[1])) {
                        listaCC.push(x)
                    }
                }
            })

            var listaFinal = []
            var distancias = []
            var tam = listaCC.length

            if (tam > 1) {
                var ccInicio = listaCC[0]
                listaCC.splice(0, 1)
                listaFinal.push(ccInicio)
                while (listaCC.length > 1) {
                    var dMin = 1000000000
                    var pos = -1
                    for (let i = 0; i < listaCC.length; i++) {
                        var dx = this.calcularDistancia(
                            ccInicio[0],
                            ccInicio[1],
                            listaCC[i][0],
                            listaCC[i][1]
                        )
                        if (dx < dMin) {
                            pos = i
                            dMin = dx
                        }
                    }
                    listaFinal.push(listaCC[pos])
                    distancias.push(dMin)
                    ccInicio = listaCC[pos]
                    listaCC.splice(pos, 1)
                }
                var coordenadas = []
                var tempos = []
                var penultimo = []
                for (let x = 0; x < listaFinal.length; x++) {
                    coordenadas.push(
                        `${this.arendondar(listaFinal[x][0], 6)},${this.arendondar(
                            listaFinal[x][1],
                            6
                        )}`
                    )
                    if (x === listaFinal.length - 1) {
                        penultimo = listaFinal[x]
                    }
                }
                var ultimo = []
                coordenadas.push(
                    `${this.arendondar(listaCC[0][0], 6)},${this.arendondar(
                        listaCC[0][1],
                        6
                    )}`
                )
                ultimo = listaCC[0]
                // eslint-disable-next-line no-unused-vars
                var distFinal = 0
                tempos.push('0 seg')
                distancias.forEach(x => {
                    distFinal += x
                    tempos.push(this.tempo(x))
                })
                var disRest = this.calcularDistancia(
                    penultimo[0],
                    penultimo[1],
                    ultimo[0],
                    ultimo[1]
                )
                distFinal += disRest
                tempos.push(this.tempo(disRest))
            }

            this.$parent.loading = false
            if (dados.length) {
                this.$parent.sendWaths(id, type)
            }
        },
        groupByKey(array, key) {
            if (!array) {
                this.$parent.loading = false
                return alert(this.$t('missao.alerta'))
            }
            return array.reduce((hash, obj) => {
                if (obj[key] === undefined) return hash
                return Object.assign(hash, {
                    [obj[key]]: (hash[obj[key]] || []).concat(obj)
                })
            }, {})
        },
        getLugar(lugar) {
            switch (lugar) {
                case 'ca':
                    return 'Vancouver'
                case 'us':
                    return 'Nova York'
                case 'gb':
                    return 'Londres'
                case 'sg':
                    return 'Singapura'
                case 'au':
                    return 'Sydney'
                default:
                    return ''
            }
        },
        async getDados(l, lc, text, k) {
            this.getBanco = true
            this.select = { val: '' }
            this.$parent.loading = true
            let data = []
            this.$emit('setEmpty')
            this.content = ''
            data.push(
                this.type,
                this.type === 3 ? l.value : 0,
                this.type === 3 ? 0 : l.value
            )
            let lcl = this.lcl
            this.select = {
                l,
                lc,
                text,
                val: `${lcl}-${l.value2 || l.value}-${text}`
            }
            let header = JSON.parse(JSON.stringify(l))
            delete header.base
            localStorage.setItem('_select', JSON.stringify(this.select))
            let quest = `quests[]=7,0,290&quests[]=${data.toString()}&time=${new Date().getTime()}&lc=${lcl}&header=${JSON.stringify(
                header
            ).replace('#', '%23')}`
            let dados = await axios(`${this.$url}/miss/v2?${quest}`).catch(e => {
                this.$parent.loading = false
                alert(e)
            })
            if (this.lcl === 'tw') {
                this.$parent.loading = false
                return this.$emit('setDados', {
                    content: l.content,
                    rewards_string: l.labelReward,
                    conditions_string: l.labelTask,
                    lcl: this.lcl,
                    id: 0
                })
            }
            let id = dados.data.id
            let headers = dados.data.header
            let date = dados.data.date
            dados = dados.data.quests
            let count = await this.groupByKey(dados, 'conditions_string')
            count = Object.keys(count).length
            if (count > 1) {
                let filterText = lc.texto ? lc.texto : lc.missoes[k].texto
                dados = dados.filter(
                    i =>
                        i.conditions_string === filterText ||
                        i.rewards_string === filterText
                )
            }
            var cc = dados.map(
                i => `${this.arendondar(i.lat, 6)},${this.arendondar(i.lng, 6)}`
            )
            var listaCC = []

            cc.forEach(i => {
                let x = i.split(',')
                if (x.length === 2) {
                    if (!isNaN(x[0]) && !isNaN(x[1])) {
                        listaCC.push(x)
                    }
                }
            })

            var listaFinal = []
            var distancias = []
            var tam = listaCC.length

            if (tam > 1) {
                var ccInicio = listaCC[0]
                listaCC.splice(0, 1)
                listaFinal.push(ccInicio)
                while (listaCC.length > 1) {
                    var dMin = 1000000000
                    var pos = -1
                    for (let i = 0; i < listaCC.length; i++) {
                        var dx = this.calcularDistancia(
                            ccInicio[0],
                            ccInicio[1],
                            listaCC[i][0],
                            listaCC[i][1]
                        )
                        if (dx < dMin) {
                            pos = i
                            dMin = dx
                        }
                    }
                    listaFinal.push(listaCC[pos])
                    distancias.push(dMin)
                    ccInicio = listaCC[pos]
                    listaCC.splice(pos, 1)
                }
                var coordenadas = []
                var tempos = []
                var penultimo = []
                for (let x = 0; x < listaFinal.length; x++) {
                    coordenadas.push(
                        `${this.arendondar(listaFinal[x][0], 6)},${this.arendondar(
                            listaFinal[x][1],
                            6
                        )}`
                    )
                    if (x === listaFinal.length - 1) {
                        penultimo = listaFinal[x]
                    }
                }
                var ultimo = []
                coordenadas.push(
                    `${this.arendondar(listaCC[0][0], 6)},${this.arendondar(
                        listaCC[0][1],
                        6
                    )}`
                )
                ultimo = listaCC[0]
                // eslint-disable-next-line no-unused-vars
                var distFinal = 0
                tempos.push('0 seg')
                distancias.forEach(x => {
                    distFinal += x
                    tempos.push(this.tempo(x))
                })
                var disRest = this.calcularDistancia(
                    penultimo[0],
                    penultimo[1],
                    ultimo[0],
                    ultimo[1]
                )
                distFinal += disRest
                tempos.push(this.tempo(disRest))
                var set = 1
                var calculoFinal = ''
                distFinal += disRest
                tempos.push(this.tempo(disRest))
                var countTag = 0
                const arrayTag = ['Ⓒ', 'ⓞ', 'ⓞ', 'ⓡ', 'ⓓ', 'ⓢ', 'Ⓖ', 'Ⓞ']
                var sizeTag = arrayTag.length
                for (let x = 1; x <= coordenadas.length; x++) {
                    if (x === 1) {
                        // eslint-disable-next-line no-irregular-whitespace
                        calculoFinal += `\nLugar: ${this.getLugar(this.lcl)}\nRecompensa: ${l.texto
                            }\nMissão: ${lc.texto_pt ? lc.texto_pt : lc.texto}\nTotal: ${coordenadas.length
                            }\n【Ｃｏｏｒｄｓ ＧＯ】\n\nSᴇᴛ ${set}`
                        set++
                    }
                    calculoFinal += `\n${arrayTag[countTag]} ${coordenadas[x - 1]} - ${tempos[x - 1]
                        }`
                    if (x % 3 === 0 && x !== coordenadas.length) {
                        calculoFinal += `\n\nSᴇᴛ  ${set}`
                        set++
                    }
                    countTag = countTag === sizeTag - 1 ? 0 : countTag + 1
                }
                if (coordenadas.length !== 0) {
                    var opt1 =
                        '\n\nRota gerada em: Coords GO\nhttps://coordsgo.com/'
                    calculoFinal += `${opt1}`
                }
                var dist = 'Distância:'

                this.saida = `${calculoFinal}\n`
                this.$emit('setSaida', this.saida)
                this.$emit('setBotao', true)
            }
            if (dados.length) {
                this.content = `${dist} ${this.arendondar(
                    distFinal,
                    3
                )} km ${calculoFinal}\n`
                this.$emit('setDados', {
                    content: this.content,
                    rewards_string: dados[0].rewards_string,
                    conditions_string: dados[0].conditions_string,
                    lcl,
                    id,
                    headers,
                    date
                })
            } else {
                console.log('aqui', dados)

                alert(this.$t('missao.alerta'))
                this.$parent.loading = false
            }
        },
        sort_categories(cat1, cat2) {
            if (cat1['tipo'] < cat2['tipo']) return -1
            if (cat1['tipo'] > cat2['tipo']) return 1
            return 0
        },
        async getList() {
            this.loading = true
            let list

            if (this.lcl === 'tw') {
                this.list = []
                let quest = `quests[]=7,0,290&quests[]&time=${new Date().getTime()}&lc=${this.lcl
                    }`
                list = await axios(`${this.$url}/miss/v2?${quest}`)
            } else {
                list = await axios(`${this.$url}/cat/missoes`).catch(
                    () => (this.loading = false)
                )
            }

            switch (this.active) {
                case 'pokemon':
                    this.type = 7
                    list.data = list.data.pokemons?.sort(this.sort_categories)
                    break
                case 'stardust':
                    this.type = 3
                    list.data = list.data.stardust
                    break
                case 'itens':
                    this.type = 2
                    list.data = list.data.itens
                    break
                case 'mega':
                    this.type = 12
                    list.data = list.data.megaEnergy || list.data.questMega
                    break
                case 'dragao':
                    this.type = 12
                    list.data = list.data.questExtra
                    break
            }

            if (this.lcl === 'tw') {
                list.data = list.data.sort((a, b) => {
                    if (a.type < b.type) return -1
                    if (a.type > b.type) return 1
                    return 0
                })
            }

            this.list = await list.data.map(i => {
                i.select = false
                try {
                    if (this.active === 'stardust') {
                        i.base = require('../assets/item/stardust_painted.png')
                    } else if (this.active === 'itens') {
                        let is
                        const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Items/'
                        if(i.value === '1') {
                            is = 'pokeball_sprite.png'
                            i.base = url + is
                        } else if (i.value === '2') {
                            is = 'greatball_sprite.png'
                            i.base = url + is
                        } else if (i.value === '3') {
                            is = 'ultraball_sprite.png'
                            i.base = url + is
                        } else if (i.value2) {
                            i.base = i.value2
                        } else {
                            is = `Item_${this.leftPad(i.value, 4)}.png`
                            i.base = url + is
                        }
                    } else if (this.active === 'mega') {
                        let m
                        if(i.value2) {
                            i.base = i.value2
                        } else {
                            m = `${this.leftPad(i.value, 3)}_51`
                            const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/'
                            i.base = `${url}pokemon_icon_${m}.png`
                        }
                    } else {
                        let v
                        if(i.value2) {
                            v = `${this.leftPad(i.value2, 3)}_00`
                        } else if (i.value5) {
                            v = `${this.leftPad(i.value, 3)}_${i.value5}`
                        } else
                        if (i.value) {
                            let nn
                            if (i.texto.match(/Alola/)) {
                                    nn = '.fALOLA'
                                } else if (i.texto.match(/Outono/)) {
                                    nn = '.fAUTUMN'
                                } else if (i.texto.match(/Primavera/)) {
                                    nn = '.fSPRING'
                                } else if (i.texto.match(/Verão/)) {
                                    nn = '.fSUMMER'
                                } else if (i.texto.match(/Inverno/)) {
                                    nn = '.fWINTER'
                                }
                                else if (i.texto.match(/Galarian/)) {
                                    nn = '.fGALARIAN'
                                } else {
                                    if (i.value == 327) {
                                        nn = '.f00'
                                    } else if (i.value == 412) {
                                        nn = '.fBURMY_PLANT'
                                    }
                                    else if (i.value == 412) {
                                        nn = '.fBURMY_PLANT'
                                    }
                                    else {
                                        nn = ''
                                    }
                                }
                            v = i.value + nn
                        }
                        const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/Addressable%20Assets/'
                        if(!i.value4) {
                            i.base = `${url}pm${v}${i.shiny ? '.s' : ''}.icon.png`
                        } else {
                            i.base = i.value4
                        }
                    }
                } catch (e) {
                    i.base = require('../assets/pokemon/0.png')
                }
                return i
            })

            this.loading = false
        },
        leftPad(value, totalWidth, paddingChar) {
            var length = totalWidth - value.toString().length + 1
            return Array(length).join(paddingChar || '0') + value
        },
        rad(x) {
            return (x * Math.PI) / 180
        },

        calcularDistancia(lat1, lon1, lat2, lon2) {
            // double R = 6378.137;//Radio de la tierra en km
            var R = 6371.0
            var dLat = this.rad(lat2 - lat1)
            var dLong = this.rad(lon2 - lon1)

            var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.rad(lat1)) *
                Math.cos(this.rad(lat2)) *
                Math.sin(dLong / 2) *
                Math.sin(dLong / 2)
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            var d = R * c

            return d
        },
        tempo(dist) {
            var myTime = ''

            if (dist <= 0.01) {
                myTime = '0seg'
            } else if (dist <= 0.1) {
                myTime = '3seg'
            } else if (dist <= 0.5) {
                myTime = '15seg'
            } else if (dist <= 1) {
                myTime = '30seg'
            } else if (dist <= 2) {
                myTime = '1min'
            } else if (dist <= 5) {
                myTime = '2min'
            } else if (dist <= 6) {
                myTime = '4min'
            } else if (dist <= 7) {
                myTime = '5min'
            } else if (dist <= 8) {
                myTime = '6min'
            } else if (dist <= 10) {
                myTime = '7min'
            } else if (dist <= 12) {
                myTime = '8min'
            } else if (dist <= 14) {
                myTime = '9min'
            } else if (dist <= 18) {
                myTime = '10min'
            } else if (dist <= 21) {
                myTime = '13min'
            } else if (dist <= 26) {
                myTime = '15min'
            } else if (dist <= 42) {
                myTime = '19min'
            } else if (dist <= 65) {
                myTime = '22min'
            } else if (dist <= 70) {
                myTime = '24min'
            } else if (dist <= 81) {
                myTime = '25min'
            } else if (dist <= 100) {
                myTime = '35min'
            } else if (dist <= 220) {
                myTime = '40min'
            } else if (dist <= 250) {
                myTime = '45min'
            } else if (dist <= 350) {
                myTime = '51min'
            } else if (dist <= 375) {
                myTime = '54min'
            } else if (dist <= 460) {
                myTime = '1hor 2min'
            } else if (dist <= 500) {
                myTime = '1hor 5min'
            } else if (dist <= 565) {
                myTime = '1hor 9min'
            } else if (dist <= 700) {
                myTime = '1hor 18min'
            } else if (dist <= 800) {
                myTime = '1hor 24min'
            } else if (dist <= 900) {
                myTime = '1hor 32min'
            } else if (dist <= 1000) {
                myTime = '1hor 39min'
            } else if (dist <= 1100) {
                myTime = '1hor 47min'
            } else if (dist <= 1200) {
                myTime = '1hor 54min'
            } else if (dist <= 1300) {
                myTime = '1hor 57min'
            } else {
                myTime = '2hor'
            }
            return myTime
        },
        arendondar(num, dec) {
            return (+(Math.round(num + 'e+' + dec) + 'e-' + dec)).toFixed(dec)
        },
        async getListCoordsRouter() {
            if (
                this.$route.name === 'missaoIdLink' ||
                this.$route.name === 'missaoIdLinkEs' ||
                this.$route.name === 'missaoIdLinkEn'
            ) {
                this.getBanco = false
                this.$emit('setBotao', false)
                this.content = ''
                this.$parent.loading = true
                let lcl = this.lcl
                let dados = await axios(
                    `${this.$url}/miss/v2/${this.$route.params.id}`
                ).catch(() => {
                    alert('url não existe!')
                    return (this.$parent.loading = false)
                })
                if (!dados.data) {
                    alert('url não existe!')
                    return (this.$parent.loading = false)
                }
                let id = dados.data.id
                let headers = dados.data.header
                let date = dados.data.date
                //dados = dados.data.quests.filter(i => i.conditions_string === text || '')
                dados = dados.data.quests
                var cc = dados.map(
                    i => `${this.arendondar(i.lat, 6)},${this.arendondar(i.lng, 6)}`
                )
                var listaCC = []

                cc.forEach(i => {
                    let x = i.split(',')
                    if (x.length === 2) {
                        if (!isNaN(x[0]) && !isNaN(x[1])) {
                            listaCC.push(x)
                        }
                    }
                })

                var listaFinal = []
                var distancias = []
                var tam = listaCC.length

                if (tam > 1) {
                    var ccInicio = listaCC[0]
                    listaCC.splice(0, 1)
                    listaFinal.push(ccInicio)
                    while (listaCC.length > 1) {
                        var dMin = 1000000000
                        var pos = -1
                        for (let i = 0; i < listaCC.length; i++) {
                            var dx = this.calcularDistancia(
                                ccInicio[0],
                                ccInicio[1],
                                listaCC[i][0],
                                listaCC[i][1]
                            )
                            if (dx < dMin) {
                                pos = i
                                dMin = dx
                            }
                        }
                        listaFinal.push(listaCC[pos])
                        distancias.push(dMin)
                        ccInicio = listaCC[pos]
                        listaCC.splice(pos, 1)
                    }
                    var coordenadas = []
                    var tempos = []
                    var penultimo = []
                    for (let x = 0; x < listaFinal.length; x++) {
                        coordenadas.push(
                            `${this.arendondar(listaFinal[x][0], 6)},${this.arendondar(
                                listaFinal[x][1],
                                6
                            )}`
                        )
                        if (x === listaFinal.length - 1) {
                            penultimo = listaFinal[x]
                        }
                    }
                    var ultimo = []
                    coordenadas.push(
                        `${this.arendondar(listaCC[0][0], 6)},${this.arendondar(
                            listaCC[0][1],
                            6
                        )}`
                    )
                    ultimo = listaCC[0]
                    // eslint-disable-next-line no-unused-vars
                    var distFinal = 0
                    tempos.push('0 seg')
                    distancias.forEach(x => {
                        distFinal += x
                        tempos.push(this.tempo(x))
                    })
                    var disRest = this.calcularDistancia(
                        penultimo[0],
                        penultimo[1],
                        ultimo[0],
                        ultimo[1]
                    )
                    distFinal += disRest
                    tempos.push(this.tempo(disRest))
                    var set = 1
                    var calculoFinal = ''
                    for (let x = 1; x <= coordenadas.length; x++) {
                        if (x === 1) {
                            calculoFinal += `Total: ${coordenadas.length} \n\nSᴇᴛ ${set}`
                            set++
                        }
                        calculoFinal += `\n${coordenadas[x - 1]} [${tempos[x - 1]}]`
                        if (x % 3 === 0 && x !== coordenadas.length) {
                            calculoFinal += `\n\nSᴇᴛ  ${set}`
                            set++
                        }
                    }
                    var dist = 'Distância:'
                }

                if (dados.length) {
                    this.content = `${dist} ${this.arendondar(
                        distFinal,
                        3
                    )} km ${calculoFinal}\n`
                    this.$emit('setDados', {
                        content: this.content,
                        rewards_string: dados[0].rewards_string,
                        conditions_string: dados[0].conditions_string,
                        lcl,
                        id,
                        headers,
                        date
                    })
                }
            }
        }
    },
    mounted() {
        this.getList()
        if (this.$route.params.id) {
            this.getListCoordsRouter(this.$route.params.id)
        }
        if (localStorage.getItem('_item')) {
            setTimeout(() => {
                this.setMenu(localStorage.getItem('_item'))
            }, 300)
        } else {
            localStorage.setItem('_item', this.active)
            this.setMenu(this.active)
        }
    }
}
</script>

<style lang="scss" scoped>
.close {
    position: absolute;
    top: -38px;
    right: 0;
    background: #fff;
    border-radius: 50%;
    height: 33px;
    width: 33px;
}

.close-img {
    height: 30px;
}

.menu {
    display: flex;
    padding: 0;
    justify-content: space-between;
    margin-block-start: 0;
    margin-bottom: 10px;

    li {
        border: 1px solid;
        padding: 5px;
        cursor: pointer;
        padding: 5px;
        width: 80px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .actived {
        background: #673bb7;

        color: #fff;

        a {
            color: #fff;
        }
    }
}

.list-corpo {
    border: 1px solid #ccc;
    margin: 10px 0;
    padding: 5px 5px;
    border-radius: 5px;

    &.active {
        background: #673bb7;
        color: #fff;

        a {
            color: #fff;
        }
    }
}

.lista {
    list-style: none;
    text-align: left;
    padding-left: 0;

    .lista-sub {
        list-style: none;

        .lista-sub-sub {
            list-style: none;
        }
    }
}

.list small {
    font-size: 11px;
}

.content-input {
    position: relative;

    .limpar {
        position: absolute;
        right: 0;
        top: 0;
        padding: 6px 16px;
        cursor: pointer;
    }
}

.search {
    display: block;
    border: 1px solid #ccc;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
}

.cart-visualizar {
    position: fixed;
    background-color: #ffffffc4;
    ;
    bottom: 102px;
    max-height: 260px;
    width: 360px;
    z-index: 2;
    border: 8px solid rgba(104, 58, 183, .7);
    padding: 12px 10px;
    border-radius: 5px;
}

.cart {
    border: 1px solid #fff;
    border-radius: 6px;
    width: 120px;
    height: 50px;
    position: fixed;
    z-index: 3;
    background: #6338af;
    color: #fff;
    bottom: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 5px;

    .count {
        color: #fff;
        font-size: 18px;
        flex: 1;
    }

    .apagar {
        flex: 1;
    }
}

.animate-open {
    animation-duration: 0.75s;
    animation-duration: 1s; //running slower to show effect
    animation-delay: 0.5s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
    animation-fill-mode: backwards;
}

.animate-open.fade {
    animation-name: animate-fade;
    animation-timing-function: ease;
}

@keyframes animate-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animate-hide {
    animation-duration: 0.75s;
    animation-duration: 2s; //running slower to show effect
    animation-delay: 0.5s;
    animation-name: animate-hide;
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
    animation-fill-mode: backwards;
}

.animate-hide.fade {
    animation-name: animate-hide;
    animation-timing-function: ease;
}

@keyframes animate-hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>


<style>
.v-toast {
    bottom: 50px !important;
}
</style>